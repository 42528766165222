<div #objWindow class="modelPreviewContainer">
  <div *ngIf="!mobileCheck()" class="hud">
    <ul>
      <li [ngClass]="{ modelHiddenTip: tipsHidden }">"Left Click" rotate</li>
      <li [ngClass]="{ modelHiddenTip: tipsHidden }">"Right Click" pan</li>
      <li [ngClass]="{ modelHiddenTip: tipsHidden }">"Middle Mouse" zoom</li>
      <br [ngClass]="{ modelHiddenTip: tipsHidden }" />
      <li [ngClass]="{ modelHiddenTip: tipsHidden }">"C" toggle camera</li>
      <!--<li [ngClass]="{'modelHiddenTip': tipsHidden}">"V" random zoom</li>-->
      <li [ngClass]="{ modelHiddenTip: tipsHidden }">"F" toggle wireframe</li>
      <br [ngClass]="{ modelHiddenTip: tipsHidden }" />
      <li>"H" toggle controls and tips</li>
    </ul>

    <div id="modelBreakDown" [ngClass]="{ modelHiddenTip: tipsHidden }">
      <p class="modelInfo" id="modelNameArea">{{ model3D.name }}</p>
      <p
        *ngIf="model3D.sourceText != ''"
        class="modelInfo"
        id="modelSourceTextWrapper"
      >
        <a id="modelSource" download="{{ model3D.source }}">
          Source: {{ model3D.sourceText }}
        </a>
      </p>
      <p *ngIf="model3D.note != ''" class="modelInfo" id="modelNote">
        {{ model3D.note }}
      </p>
    </div>
  </div>

  <button
    *ngIf="mobileCheck() && !pressed"
    class="loadModel"
    (click)="startPreview()"
  >
    Load Model
  </button>
</div>
