<section id="apps" class="flexCol projectSection">
  <div class="header flexRow alignItemsCenter justifyContentCenter flexWrap">
    <h2>Websites and Apps</h2>
    <div class="noticeContainer flexRow alignItemsCenter justifyContentCenter">
      <span class="material-symbols-outlined"> info </span>
      <div class="notice">
        This site is only for my personal projects and an introduction. You can
        read my professional work history on my
        <a
          href="https://drive.google.com/file/d/1O4ADWBJWAXxdPd4sYMtNKt_Ez0cDDsEy/view?usp=sharing"
          >web-focused resume</a
        >.
      </div>
    </div>
  </div>
  <ul class="projectsContainer">
    <ng-container *ngFor="let project of APPS">
      <!--Normal-->
      <li
        class="project flex flexRow"
        [style.background]="project.background"
        [style.background-color]="project.backgroundColor"
        [style.cursor]="project.link != null ? 'pointer' : 'inherit'"
        (click)="redirect(project.link)"
      >
        <!--Image options-->
        <img
          *ngIf="project.type == null"
          class="workImage width100"
          src="{{ project.displayMediaPath }}"
        />
        <app-ssi *ngIf="project.type == 'SSI'" class="workImage"></app-ssi>
        <app-mac *ngIf="project.type == 'MAC'" class="workImage"></app-mac>
        <app-anim-signature
          *ngIf="project.type == 'signature'"
          class="workImage"
        ></app-anim-signature>

        <!--Content-->
        <div class="workContentBg width100">
          <div class="workContentContainer width100">
            <div class="workContentWrapper flexCol alignItemsEnd width100">
              <h3 class="workHeader">{{ project.name }}</h3>
              <div class="workDate">
                <span class="material-symbols-outlined"> event </span
                ><em>{{ project.date }}</em>
              </div>
              <!--<a
                class="workLink"
                *ngIf="project.link != null"
                href="{{ project.link }}"
                >click to go to project</a
              >-->
              <a
                class="workLink"
                *ngIf="project.link != null"
                href="{{ project.source }}"
                >{{ project.sourceText }}</a
              >
              <!--<div class="activeTriangle"></div>-->
            </div>
            <div class="workDesc">{{ project.description }}</div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="footer">
    <div class="noticeContainer flexRow alignItemsCenter justifyContentCenter">
      <span class="material-symbols-outlined"> info </span>
      <div class="notice">
        Hover a project to view more or click on a project to see the result.
      </div>
    </div>
  </div>
</section>
<app-about-apps *ngIf="resumeService.isApps()"></app-about-apps>
