import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mac',
  templateUrl: './mac.component.html',
  styleUrls: ['./mac.component.scss']
})
export class MacComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
