<section id="games" class="flexCol projectSection">
  <div class="header flexRow alignItemsCenter justifyContentCenter flexWrap">
    <h2>Games</h2>
    <div class="noticeContainer flexRow alignItemsCenter justifyContentCenter">
      <span class="material-symbols-outlined"> info </span>
      <div class="notice">
        This site is only for my personal projects and an introduction. You can
        read my professional work history on my
        <a
          href="https://drive.google.com/file/d/1O4ADWBJWAXxdPd4sYMtNKt_Ez0cDDsEy/view?usp=sharing"
          >game-focused resume</a
        >.
      </div>
    </div>
  </div>
  <ul class="projectsContainer">
    <li *ngFor="let project of games" class="flex flexCol width100">
      <app-project-listing [project]="project"></app-project-listing>
    </li>
  </ul>
  <div class="footer">
    <div class="noticeContainer flexRow alignItemsCenter justifyContentCenter">
      <span class="material-symbols-outlined"> info </span>
      <div class="notice">
        On each game above, click "showcase" to view more.
      </div>
    </div>
  </div>
</section>

<app-about-games *ngIf="resumeService.isGames()"> </app-about-games>
