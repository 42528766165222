<section id="about" class="flexCol projectSection">
  <div class="header flexRow alignItemsCenter justifyContentCenter flexWrap">
    <h2>About Me</h2>
  </div>
  <div class="aboutContainer">
    <p>Hello!</p>
    <p>I'm Andrew.</p>
    <p>
      I am an aspiring game designer, developer, and gamer (of course)!<br />
      I love taking initiative and trying new things.<br />
      Game design and development is my passion; I started game development when
      I was 12!
    </p>
    <h3>My Skills</h3>
    <div id="bigThree" class="flexRow flexWrap">
      <div class="three flexGrow">
        <div class="categoryHeader flexRow alignItemsCenter">
          <img class="categoryHeaderImg" src="assets/res/passions/design.svg" />
          <div class="categoryHeaderText">Design</div>
        </div>
        <div class="threeContent">
          <div>
            <span class="material-symbols-outlined"> draw </span>
            <span
              >As a <span class="accentHighlight">game designer</span>, I
              believe games should be <em>fun, </em> <em>atmospheric</em>, or
              <em>building to something greater</em>.
            </span>
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> interests </span>
            <span class="accentHighlight">Uniqueness.</span>
            I greatly enjoy coming up with ideas to help making something more
            unique. Uniqueness is one of the best "catches" of a good game.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> group_add </span>
            <span class="accentHighlight">Co-op.</span>
            Being able to play with your friends, or other players, greatly
            improves the (re)playability of almost any game. There are obvious
            exceptions like atmostpheric solo-horror games like Visage & Dead
            Space.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> edit_note </span>
            <span class="accentHighlight">Writing.</span>
            I love to write. Story telling is a key part of most of my favorite
            games. When I think back to my childhood, I get a wave of nostalgia
            looking up at the top of the Ring for the first time.
          </div>
          <br />
        </div>
      </div>
      <div class="three flexGrow">
        <div class="categoryHeader flexRow alignItemsCenter">
          <img
            class="categoryHeaderImg"
            src="assets/res/passions/developer.svg"
          />
          <div class="categoryHeaderText">Development</div>
        </div>
        <div class="threeContent">
          <div class="categoryHeaderHeader">
            <span class="material-symbols-outlined"> code_blocks </span>
            As a <span class="accentHighlight">software developer</span>, I have
            created games, websites, and applications.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> receipt_long </span>
            My <span class="accentHighlight">experience</span> with game
            development is above, and I also have 2 years of professional
            experience as a Full Stack Developer.
            <a [href]="resumeService.resumeGame">View my resume.</a>
          </div>
          <br />
          <div class="categoryHeaderParagraph bitLabel">
            <span class="material-symbols-outlined"> terminal </span>
            I have experience with many
            <span class="accentHighlight">lanaguages</span>,
            <span class="accentHighlight">programs</span> and
            <span class="accentHighlight">tools</span>, such as:
          </div>
          <ul>
            <li>
              <strong><span class="bitLabel">Languages:</span></strong> C++, UE
              Blueprints, C#, Java, TypeScript, Kotlin
            </li>
            <li>
              <strong><span class="bitLabel">Programs:</span></strong> Unreal
              Engine, Unity, Android Studio
            </li>
            <li>
              <strong><span class="bitLabel">IDEs:</span></strong> Visual
              Studio, VS Code, JetBrains Suite
            </li>
            <li>
              <strong><span class="bitLabel">Graphic Design:</span></strong>
              Photoshop, Illustrator, Inkscape
            </li>
            <li>
              <strong><span class="bitLabel">3D:</span></strong> Blender, 3DS
              Max, Maya, Quixel Mixer
            </li>
            <li>
              <strong><span class="bitLabel">Devops:</span></strong> Azure
            </li>
          </ul>
        </div>
      </div>
      <div class="three flexGrow">
        <div class="categoryHeader flexRow alignItemsCenter">
          <img class="categoryHeaderImg" src="assets/res/passions/gamer.svg" />
          <div class="categoryHeaderText">Gaming</div>
        </div>
        <div class="threeContent">
          <div class="categoryHeaderHeader">
            <span class="material-symbols-outlined"> sports_esports </span> Of
            course, <span class="accentHighlight">I'm a gamer!</span> I play a
            wide array of games from any genre.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> new_releases </span>
            <span class="accentHighlight">Playing new games</span> to see what's
            new is the best way to see what works! You can playtest and review
            how another developer was successful at creating a fun experience.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> center_focus_weak </span>
            <span class="accentHighlight">Analyzing and critiquing</span> games
            is fun! Looking at someone else's work from a game design lens helps
            me understand design and expand my own perspective.
          </div>
          <br />
          <!--<div>
          <span class="material-symbols-outlined"> new_releases </span>
          I have been playing games <span class="accentHighlight">since I was 2</span>! There is a picture of me
          playing a PC game sitting on my dad's lap playing a game on Windows 2000.
        </div>
        <br />-->
          <div>
            <span class="material-symbols-outlined"> favorite </span>
            Some of
            <span class="accentHighlight"> my favorite games</span>
            include: <br />
            The Last of Us Part I & II, Pokemon, Rainbow Six: Siege, Halo, the
            Forest, Anno 1800, Read Dead Redemption 2, Labrynthine, Valheim,
            Limbo, SimCity 4, The Sims, Sly and Spyro.
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</section>
