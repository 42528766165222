<section id="about" class="flexCol projectSection">
  <div class="header flexRow alignItemsCenter justifyContentCenter flexWrap">
    <h2>About Me</h2>
  </div>
  <div class="aboutContainer">
    <p>Hello!</p>
    <p>I'm Andrew.</p>
    <p>
      I am an
      <span class="accentHighlight">initiative-taking</span>,
      <span class="accentHighlight">Agile</span>,
      <span class="accentHighlight">full stack</span> software developer.<br /><br />
      I love to use <span class="accentHighlight">modern practices</span> and
      <span class="accentHighlight">languages</span> to keep code clean,
      readable, optimized and relevant.<br /><br />
      I exercise
      <span class="accentHighlight">entrepreneurial determination</span> to
      complete my work effectively and to
      <span class="accentHighlight">motivate my team members</span>.
    </p>
    <h3>My Skills</h3>
    <div id="bigThree" class="flexRow flexWrap">
      <div class="three flexGrow">
        <div class="categoryHeader flexRow alignItemsCenter">
          <img
            class="categoryHeaderImg"
            src="assets/res/passions/developer.svg"
          />
          <div class="categoryHeaderText">Development</div>
        </div>
        <div class="threeContent">
          <div class="categoryHeaderHeader">
            <span class="material-symbols-outlined"> code_blocks </span>
            As a <span class="accentHighlight">software developer</span>, I have
            created <span class="accentHighlight">web-apps</span>,
            <span class="accentHighlight">mobile apps</span>,
            <span class="accentHighlight">websites</span>, and even video games!
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> receipt_long </span>
            My personal project
            <span class="accentHighlight">experience</span> is enumerated above.
            I have 2 years of professional experience as a
            <span class="accentHighlight">Full Stack Developer</span>, which you
            can read more about
            <a [href]="resumeService.resumeApp">on my resume.</a>
          </div>
          <br />
          <div class="categoryHeaderParagraph bitLabel">
            <span class="material-symbols-outlined"> terminal </span>
            I am experienced with many
            <span class="accentHighlight">lanaguages</span>,
            <span class="accentHighlight">programs</span> and
            <span class="accentHighlight">tools</span>, such as:
          </div>
          <ul>
            <li>
              <strong><span class="bitLabel">Languages:</span></strong> Angular,
              TypeScript, C# .NET, C++, Java, Kotlin
            </li>
            <li>
              <strong><span class="bitLabel">Devops:</span> </strong> Azure
              Portal & DevOps
            </li>
            <li>
              <strong><span class="bitLabel">UX Planning:</span></strong>
              Figma, Lucid.app, Draw.io
            </li>
            <li>
              <strong><span class="bitLabel">Graphic Design:</span></strong>
              Photoshop, Illustrator, Inkscape
            </li>
            <li>
              <strong><span class="bitLabel">Programs:</span> </strong> Android
              Studio, Unreal Engine, Unity
            </li>
            <li>
              <strong><span class="bitLabel">IDEs:</span></strong> Visual
              Studio, VS Code, JetBrains Suite
            </li>
          </ul>
        </div>
      </div>
      <div class="three flexGrow">
        <div class="categoryHeader flexRow alignItemsCenter">
          <img class="categoryHeaderImg" src="assets/res/passions/design.svg" />
          <div class="categoryHeaderText">Design</div>
        </div>
        <div class="threeContent">
          <div>
            <span class="material-symbols-outlined"> draw </span>
            <span
              >As a <span class="accentHighlight">web & app designer</span>, I
              have developed multiple web apps for multiple clients.
              <a [href]="resumeService.resumeApp">View my resume.</a>
              I have also designed multiple personal sites and brandings
              (displayed above).
            </span>
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> mop </span>
            <span class="accentHighlight">Cleanliness.</span>
            I like websites to be clean and clear. It is important to look at a
            UI and audit it, asking yourself, "Is this too much?" I believe the
            best sites are <em>short</em>, <em>sweet</em>,
            <em>deliberate</em> and <strong>powerful</strong>.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> share_reviews </span>
            <span class="accentHighlight">Feedback.</span>
            The best ideas rarely come from one person exclusively. Feedback and
            testing are critical parts of developing quality designs.
          </div>
          <br />
          <div>
            <span class="material-symbols-outlined"> groups </span>
            <span class="accentHighlight">Collaboration.</span>
            Tools like Figma are important to allow group access to all designs
            and also to expose work for feedback and review opportunities.
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</section>
