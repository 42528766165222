<section id="contact" class="projectSection flexCol justifyContentCenter">
  <div class="flexRow alignItemsCenter justifyContentCenter flexWrap">
    <h2>Contact Me</h2>
  </div>

  <div class="flexRow flexWrap contactItemsContainer justifyContentCenter">
    <a
      class="flexRow alignItemsCenter contactContainer hvr-float resume"
      [href]="resumeService.resumeLink"
      >Resume</a
    >
    <a
      class="flexRow alignItemsCenter contactContainer hvr-grow-rotate"
      href="mailto:andrew@andrewpodrat.com"
    >
      <img
        class="contactIcon"
        src="assets/res/material.io/mail-white-18dp.svg"
      />
      <span>andrew@andrewpodrat.com</span>
    </a>
<!--    <a-->
<!--      class="flexRow alignItemsCenter contactContainer hvr-float"-->
<!--      id="fakeLinkedInWrapper"-->
<!--      href="https://www.linkedin.com/in/andrewpodrat/"-->
<!--    >-->
<!--      <div class="noselect" id="fakeLinkedIn">in</div>-->
<!--      <div>LinkedIn</div>-->
<!--    </a>-->
    <a
      class="flexRow alignItemsCenter contactContainer hvr-pulse"
      href="https://github.com/Ragnaraven"
    >
      <img
        class="contactIcon hvr-grow-rotate"
        src="assets/res/github/github-big.svg"
      />
      <div>GitHub</div>
    </a>
    <div class="flexRow alignItemsCenter contactContainer hvr-bob">
      <img
        class="contactIcon"
        src="assets/res/material.io/place-white-18dp.svg"
      />
      <div>Pittsburgh, PA</div>
    </div>
  </div>

  <div class="flexGrow"></div>
</section>
