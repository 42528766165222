<div id="bgWrapper">
  <div id="cloudBG">
    <svg id="sunRays" width="60" height="75vh">
      <rect width="60px" height="100vh" style="fill: rgb(0, 0, 0)" />
    </svg>

    <div id="suns">
      <img id="sunBack" src="assets/res/components/mac/sun.svg" alt="sunBg" />
      <img id="sunFront" src="assets/res/components/mac/sun.svg" alt="sun" />
    </div>

    <img
      id="hillsThree"
      src="assets/res/components/mac/hill3.svg"
      alt="hill3"
    />
    <img id="hillsTwo" src="assets/res/components/mac/hill2.svg" alt="hill2" />
    <img id="hillsOne" src="assets/res/components/mac/hill1.svg" alt="hill1" />

    <div id="cloudWrapper">
      <img
        class="cloud"
        id="cloud1"
        src="assets/res/components/mac/cloud1.svg"
        alt="cloud"
      />
      <img
        class="cloud"
        id="cloud2"
        src="assets/res/components/mac/cloud2.svg"
        alt="cloud"
      />
      <img
        class="cloud"
        id="cloud3"
        src="assets/res/components/mac/cloud3.svg"
        alt="cloud"
      />
      <img
        class="cloud"
        id="cloud4"
        src="assets/res/components/mac/cloud4.svg"
        alt="cloud"
      />
      <img
        class="cloud"
        id="cloud5"
        src="assets/res/components/mac/cloud5.svg"
        alt="cloud"
      />
    </div>
  </div>
  <div id="animationIn">
    <div id="three" class="wow slideOutLeft" data-wow-delay="0.5s"></div>
    <div id="two" class="wow slideOutLeft" data-wow-delay="0.3s"></div>
    <div id="one" class="wow slideOutLeft"></div>
  </div>
</div>
